import React, { Component } from 'react';
import Select from 'react-select';
import './HeaderComponent.css';
import { Option } from './types';

type Props = {
    selectedOption: Option | null;
    handleChange: (value: any) => void;
    options: Option[];
};

type StyleOptions = {
    isFocused: boolean;
};

class HeaderComponent extends Component<Props> {
    render() {
        const colourStyles: any = {
            option: (styles: [], { isFocused }: StyleOptions) => {
                return {
                    ...styles,
                    backgroundColor: isFocused ? '#6868cf' : null,
                    color: isFocused ? '#FFF' : '#333333',
                };
            },
        };

        return (
            <div className="mainHeader">
                <Select
                    value={this.props.selectedOption}
                    onChange={this.props.handleChange}
                    options={this.props.options}
                    placeholder="Select API version..."
                    styles={colourStyles}
                />
            </div>
        );
    }
}
export default HeaderComponent;
