import React, { Component } from 'react';
import _ from 'lodash';
import HeaderComponent from './HeaderComponent';
import { RedocStandalone } from 'redoc';
import { Option } from './types';
const configuration = require('./config.json');

type Props = {};

type State = {
    listOptions: Option[];
    specFiles: {
        [keys: number]: string;
    };
    selectedOption: null | Option;
    selectedSpecFile: null;
};

class RedocComponent extends Component {
    constructor(props: Props) {
        super(props);
        let counter = 0;
        if (_.has(configuration, this.getEnv())) {
            _.sortBy(configuration[this.getEnv()], ['showOrder']).forEach((version) => {
                const thisOption = { value: version.version, label: version.title };
                this.state.listOptions.push(thisOption);
                this.state.specFiles[version.version] = version.specfile;
                if (!counter) {
                    this.state.selectedOption = thisOption;
                    this.state.selectedSpecFile = version.specfile;
                }
                counter++;
            }, this);
        }
        this.handleChange = this.handleChange.bind(this);
    }

    state: State = {
        listOptions: [],
        specFiles: {},
        selectedOption: null,
        selectedSpecFile: null,
    };

    getEnv() {
        if (document.location.href.includes('apidocs.wyscout.com')) {
            return 'prod';
        } else if (document.location.href.includes('apidocsrc.wyscout.com')) {
            return 'rc';
        }

        return 'beta';
    }

    handleChange(selectedOption: Option) {
        let selectedFile = this.state.specFiles[selectedOption.value];
        this.setState({
            selectedOption: selectedOption,
            selectedSpecFile: selectedFile,
        });
    }

    render() {
        return (
            <div>
                <HeaderComponent
                    options={this.state.listOptions}
                    handleChange={this.handleChange}
                    selectedOption={this.state.selectedOption}
                />
                {this.state.selectedSpecFile && (
                    <RedocStandalone
                        specUrl={process.env.PUBLIC_URL + this.state.selectedSpecFile}
                    />
                )}
            </div>
        );
    }
}
export default RedocComponent;
